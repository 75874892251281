@import './variables.scss';

.#{$prefix}__button-reject,
.#{$prefix}__button-no,
.#{$prefix}__button-yes,
.#{$prefix}__button-main,
.#{$prefix}__button-main-black,
.#{$prefix}__button,
.#{$prefix}__button-small {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 15px;
    max-width: 490px;
    color: var(--text-black);
    &:disabled {
        cursor: not-allowed;
    }
}

.#{$prefix}__button-main,
.#{$prefix}__button-main-black,
.#{$prefix}__button {
    width: 100%; //325px;
    height: $btn-height;
    padding: 0 20px;
}

.#{$prefix}__button-main {
    background-color: #fff;
    color: var(--text-black);
    border: none;
    &::before,
    &::after {
        opacity: 1;
    }
    &:disabled {
        background-color: var(--border-gray);
        color: var(--text-gray);
        &::before,
        &::after {
            opacity: 0.5;
        }
    }
    &:active {
        background-color: var(--text-black);
        color: #fff;
    }
}

.#{$prefix}__button-main-black {
    background-color: var(--text-black);
    color: #fff;
    border: none;
    &:not(:disabled) {
        &:hover {
            &::after {
                content: '';
                width: 15px;
                height: 7px;
                background: url('../assets/svg/arrow-right.svg') no-repeat center;
                position: absolute;
                right: 20px;
            }
        }
    }
    &:disabled {
        background-color: var(--border-gray);
        color: var(--text-gray);
        &:hover {
            &::after {
                content: none;
            }
        }
    }
}

.#{$prefix}__button {
    border: 1px solid var(--border-gray);
    background-color: unset;
    &:not(:disabled) {
        &:hover {
            border: 1px solid var(--text-black);
        }
    }
    &:disabled {
        border-color: var(--border-gray);
    }
    &:active {
        border: 1px solid var(--text-black);
        border-radius: 32px;
    }
}

.#{$prefix}__button-small {
    height: 32px;
    font-size: 13px;
    line-height: 15px;
    padding: 7px 14px;
    background-color: var(--text-black);
    color: #fff;
    border: none;
    border-radius: 20px;
    cursor: pointer;
}

.#{$prefix}__button-close {
    position: relative;
    width: $round-btn-size;
    height: $round-btn-size;
    border-radius: 50%;
    border: 1px solid var(--text-black);
    background: transparent url('../assets/svg/x.svg') no-repeat center;
    cursor: pointer;
    flex-shrink: 0;
}

.#{$prefix}__button-no,
.#{$prefix}__button-yes {
    color: var(--text-black);
    padding: 24px 40px;
}

.#{$prefix}__button-no {
    border: 1px solid var(--red);
    background: #fff url('../assets/svg/x.svg') no-repeat calc(50% + 25px) center;
    &:active,
    &--selected {
        color: #fff;
        background-color: var(--red);
        background-image: url('../assets/svg/x-white.svg');
    }
}

.#{$prefix}__button-yes {
    border: 1px solid var(--green);
    background: #fff url('../assets/svg/tick.svg') no-repeat calc(50% + 25px) center;
    &:active,
    &--selected {
        color: #fff;
        background-color: var(--green);
        background-image: url('../assets/svg/tick-white.svg');
    }
}

.#{$prefix}__button-reject {
    border: 1px solid var(--red);
    background-color: #fff;
    padding: 24px 40px;
    &:hover {
        background-color: var(--red);
        color: #fff;
    }
}

.#{$prefix}__button-refresh,
.#{$prefix}__button-like {
    z-index: 1;
    position: absolute;
    background-color: transparent;
    width: 36px;
    height: 36px;
    border: none;
    cursor: pointer;
    &:hover {
        transform: scale(1.1);
    }
}

.#{$prefix}__button-refresh {
    top: 0;
    left: 0;
    background: transparent url('../assets/svg/refresh.svg') no-repeat center;
    transition: transform ease-in-out 0.2s;
    &:active {
        transform: rotate(-180deg);
    }
}

.#{$prefix}__button-like {
    top: 0;
    right: 0;
    background: transparent url('../assets/svg/heart.svg') no-repeat center;
    &--liked {
        background-image: url('../assets/svg/heart-fill-black.svg');
    }
    &--big {
        background-size: 80%;
    }
}

.#{$prefix}__button-nav {
    border: 1px solid var(--border-gray);
    width: 50px;
    height: 30px;
    border-radius: 20px;
    cursor: pointer;
    background: #fff url('../assets/svg/arrow-tick-left.svg') no-repeat center;
    &--next {
        transform: rotate(180deg);
    }
    &:hover {
        background-color: var(--back-gray);
    }
    &:disabled {
        opacity: 0.5;
        background-color: #fff;
        cursor: not-allowed;
    }
}

.#{$prefix}__button-nav-vert {
    border: 1px solid var(--border-gray);
    width: 30px;
    height: 30px;
    border-radius: 50%;
    cursor: pointer;
    background: rgba(255, 255, 255, 0.8) url('../assets/svg/arrow-tick-left.svg') no-repeat center;
    transform: rotate(90deg) translateY(50%);
    position: absolute;
    left: 50%;
    top: 20px;
    z-index: 3;
    &--down {
        transform: rotate(270deg) translateY(-50%);
        top: auto;
        bottom: 20px;
    }
    &:hover {
        background-color: #fff;
    }
    &:disabled {
        opacity: 0.5;
        background-color: transparent;
        cursor: not-allowed;
    }
}

.#{$prefix}__button-next,
.#{$prefix}__button-prev {
    z-index: 1;
    position: absolute;
    width: 30px;
    height: 30px;
    bottom: 10px;
    border: 1px solid var(--border-gray);
    border-radius: 50%;
    cursor: pointer;
    background: rgba(255, 255, 255, 0.8) url('../assets/svg/arrow-tick-left.svg') no-repeat center;
    background-size: 7px;
    &:hover {
        background-color: var(--back-gray);
    }
}

.#{$prefix}__button-prev {
    left: 10px;
}

.#{$prefix}__button-next {
    right: 10px;
    transform: rotate(180deg);
}

@media screen and (min-width: $desktop-small) {
    .#{$prefix}__button-no,
    .#{$prefix}__button-yes {
        background-position: center;
    }
    .#{$prefix}__button-no {
        &:hover {
            background-color: var(--red);
            background-image: url('../assets/svg/x-white.svg');
        }
    }
    .#{$prefix}__button-yes {
        &:hover {
            background-color: var(--green);
            background-image: url('../assets/svg/tick-white.svg');
        }
    }

    .#{$prefix}__button-reject {
        width: 100%;
        height: 40px;
    }

    .#{$prefix}__button-small {
        height: 40px;
        padding: 7px 18px;
    }

    .#{$prefix}__button-main {
        &:hover {
            background-color: var(--text-black);
            color: #fff;
        }
    }
}
