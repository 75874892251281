@import '../../../../styles/variables.scss';

.#{$prefix}__layout-center {
    min-height: calc(100vh - #{$mobile-header-height});
}

.#{$prefix}__products {
    @include grid(2, 15px);
    margin-bottom: 20px;
}

.#{$prefix}__user-product {
    @include grid(2, 15px);
    margin-bottom: 25px;
}

.#{$prefix}__user-product-text {
    margin: 30px 0 50px 0;
}

.#{$prefix}__sticky-controls {
    display: flex;
    width: 100%;
    position: sticky;
    bottom: 0;
}

.#{$prefix}__btns {
    display: flex;
    grid-gap: 5px;
    position: relative;
}

.#{$prefix}__controls-btn {
    width: 50%;
    border: 1px solid var(--border-gray);
    position: relative;
    min-width: 160px;
    &--prev {
        border-right: none;
        &::before {
            content: url('../../../../assets/svg/arrow-right-black.svg');
            margin-right: 20px;
            transform: rotate(180deg) translateX(70px);
            position: absolute;
            left: 50%;
        }
    }
    &--next {
        &::after {
            content: url('../../../../assets/svg/arrow-right-black.svg');
            margin-left: 20px;
            position: absolute;
            transform: translateX(70px);
            right: 50%;
        }
    }
}

.#{$prefix}__btn-buy {
    width: 100%;
    min-width: 210px;
}

.#{$prefix}__main-block-card-image {
    width: 100%;
}

@media screen and (min-width: $desktop-small) {
    .#{$prefix}__products {
        @include grid(2, 20px);
    }
    .#{$prefix}__main-block-card-wrapper {
        display: none;
    }
    .#{$prefix}__layout-center {
        width: 450px;
        order: 2;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        flex-wrap: wrap;
        padding-top: 100px;
        min-height: 100%;
        background-color: var(--back-gray);
        position: absolute;
        right: 0;
        z-index: 80;
    }
    .#{$prefix}__layout-right {
        width: calc(100% - 450px - #{$left-panel-width});
        order: 1;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        border-right: 1px solid var(--border-gray);
        position: fixed;
        height: 100vh;
        overflow-y: auto;
    }
    .#{$prefix}__btn-back {
        margin-bottom: 35px;
    }
    .#{$prefix}__main-block-header {
        padding-top: 40px;
    }
    .#{$prefix}__btn-buy {
        width: 100%;
        margin-bottom: 5px;
    }
    .#{$prefix}__controls {
        width: 100%;
    }
    .#{$prefix}__controls-btn {
        &--prev {
            width: 100%;
            border: 1px solid var(--border-gray);
        }
        &--next {
            width: 100%;
        }
    }
}

@media screen and (min-width: $desktop-medium) {
    .#{$prefix}__layout-center {
        width: 660px;
    }
    .#{$prefix}__layout-right {
        width: calc(100% - 660px - #{$left-panel-width});
    }
    .#{$prefix}__products {
        @include grid(3, 20px);
    }
}

@media screen and (min-width: ($desktop-large + 1px)) {
    .#{$prefix}__layout-right {
        position: static;
        width: calc(100% - 760px);
    }
    .#{$prefix}__layout-center {
        width: 760px;
        height: 100vh;
        overflow-y: auto;
    }
}
