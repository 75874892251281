@import '../../../styles/variables.scss';

.#{$prefix} {
    width: 100%;
    margin-bottom: 5px;
}

.#{$prefix}__picture-container {
    position: relative;
}

.#{$prefix}__picture {
    position: relative;
    width: 100%;
    padding-top: $image-proportion-height;
    border: 1px solid transparent;
    //border: 1px solid var(--border-gray);
    background-color: #fff;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    margin-bottom: 15px;
}

.#{$prefix}__details {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.#{$prefix}__details-info {
    width: calc(100% - 10px - 71px);
    text-align: left;
}

.#{$prefix}__brand {
    color: var(--text-gray);
    margin-bottom: 3px;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
}

.#{$prefix}__overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
}

@media screen and (min-width: $desktop-small) {
    .#{$prefix}__picture {
        &:hover {
            border-color: var(--border-gray);
        }
    }
}
