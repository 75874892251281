@import './styles/variables.scss';

.#{$prefix}__line {
    display: none;
    //width: calc(#{$left-panel-width} + #{$left-panel-left-gap} + #{$left-panel-right-gap});
    margin-left: -$left-panel-left-gap;
    margin-right: -$left-panel-right-gap;
    margin-bottom: 25px;
}

@media screen and (min-width: $desktop-small) {
    .#{$prefix}__line {
        display: block;
    }
}
