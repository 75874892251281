@import '../../../styles/variables.scss';

.#{$prefix} {
    background: radial-gradient(53.23% 67.02% at 49.85% 117.23%, #b4ffd9 0%, rgba(121, 188, 239, 0.7) 100%);
}

.#{$prefix}__page-title {
    margin-bottom: 20px;
}

.#{$prefix}__subtitle {
    margin-bottom: 20px;
}

.#{$prefix}__layout-center {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
    z-index: 5;
}

.#{$prefix}__btn {
    width: 100%;
}

@media screen and (min-width: $desktop-small) {
    .#{$prefix}__subtitle {
        margin-bottom: 20px;
    }
}
