@import '../../../styles/variables.scss';

$btn-share-icon-box-width: 50px;
$btn-gap: 10px;

.#{$prefix}__button {
    border: 1px solid var(--border-gray);
    background: transparent url('../../../assets/svg/share.svg') no-repeat center;
    width: $btn-share-icon-box-width;
    height: $btn-height;
    &:hover {
        background-color: var(--back-gray);
    }
    &--opened {
        background-image: url('../../../assets/svg/x.svg');
    }
}

.#{$prefix}__btns-list {
    border: 1px solid var(--text-black);
    background-color: #fff;
    position: absolute;
    right: 0;
    bottom: $btn-height + 2 * $btn-gap;
    width: 100%;
}

.#{$prefix}__btns-list-item {
    border-bottom: 1px solid var(--text-black);
    &:last-child {
        border-bottom: none;
    }
    &:hover {
        background-color: var(--back-gray);
    }
}

.#{$prefix}__btns-list-link {
    padding: 15px 20px;
    display: block;
    cursor: pointer;
}

@media screen and (min-width: $desktop-small) {
    .#{$prefix} {
        position: relative;
    }

    .#{$prefix}__button {
        margin-left: calc(100% - #{$btn-share-icon-box-width});
        cursor: pointer;
        &::before {
            content: 'Поделиться образом';
            position: absolute;
            left: calc(100% - #{$btn-share-icon-box-width});
            color: var(--text-gray);
            transform: translate(-100%, -50%);
            width: calc(100% - #{$btn-share-icon-box-width});
        }
    }

    .#{$prefix}__btns-list {
        bottom: $btn-height + $btn-gap;
    }

    .#{$prefix}__btns-list-item {
        &--whatsapp {
            display: none;
        }
    }
}
