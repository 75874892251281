@import '../../../styles/variables.scss';

.#{$prefix} {
    overflow: hidden;
}

.#{$prefix}__title-nav {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 20px;
    &--with-title {
        justify-content: space-between;
    }
}

.#{$prefix}__nav-btns {
    display: flex;
    grid-gap: 5px;
}

.#{$prefix}__products-list {
    display: flex;
    grid-gap: 15px;
    overflow-x: scroll;
    position: relative;
    flex-wrap: wrap;
}

.#{$prefix}__products-list-item {
    width: calc(50% - 15px * 1 / 2);
}

.#{$prefix}__product-item {
    width: 180px;
    // todo set styles for mobile
}

.#{$prefix}__picture-container {
    padding-top: $image-proportion-height;
    margin-bottom: 15px;
}

.#{$prefix}__picture {
    height: 100%;
}

.#{$prefix}__link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

@media screen and (min-width: $desktop-small) {
    .#{$prefix}__products-list {
        display: flex;
        overflow-x: scroll;
        position: relative;
        grid-gap: 20px;
    }
    .#{$prefix}__products-list-item {
        width: calc(25% - 20px * 3 / 4);
    }
}
