@import '../../../styles/variables.scss';

$width: 325px;
$pic-width-big: 390px;
$pic-height-big: 550px;
$robot-closed-height: 100px;

.#{$prefix}__layout {
    flex-direction: column;
    &--similar,
    &--recommended,
    &--popular {
        .#{$prefix}__button-buy {
            margin-bottom: 15px;
        }
        .#{$prefix}__robot-block {
            display: flex;
        }
    }
    &--total-look {
        .#{$prefix}__robot-products-block {
            display: block;
        }
    }
    &--similar {
        justify-content: space-between;
    }
}

.#{$prefix}__content {
    padding: 0 $side-gap-mob;
    padding-top: 25px;
    width: $mobile-medium;
    max-width: 100%;
    margin: 0 auto;
}

.#{$prefix}__picture-container {
    position: relative;
    width: 100%;
    margin-bottom: 10px;
}

.#{$prefix}__picture {
    width: 100%;
    height: auto;
    background-color: transparent;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    margin-bottom: 10px;
    cursor: pointer;
    border: none;
    padding: 0;
    margin: 0;
}

.#{$prefix}__picture-img {
    width: 100%;
}

.#{$prefix}__details {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.#{$prefix}__brand {
    color: var(--text-gray);
    width: 260px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.#{$prefix}__robot-block {
    display: none;
    flex-direction: column;
    align-items: center;
    left: 0;
    bottom: 0;
    width: 100%;
    text-align: center;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    padding: 15px 25px 25px 25px;
    z-index: 300;
    background: radial-gradient(119.35% 65.46% at 127.68% 15.55%, #fff0ba 0%, rgba(250, 144, 78, 0) 100%),
        radial-gradient(81.09% 160.32% at 10.91% 7.43%, rgba(255, 132, 79, 0.7) 0%, rgba(253, 169, 107, 0) 100%),
        radial-gradient(38.62% 73.86% at 46.15% 108.51%, #fff8bc 2.1%, rgba(254, 180, 120, 0) 100%), linear-gradient(135.54deg, #f2681c 1.29%, #ffb36c 75.24%);
}

.#{$prefix}__robot-title {
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 20px;
    color: #fff;
    margin-bottom: 20px;
    width: 190px;
}

.#{$prefix}__thumbnails {
    display: flex;
    overflow-x: scroll;
    width: 100%;
    position: relative;
}

.#{$prefix}__thumbs-slider {
    position: relative;
    width: 100%;
    height: 100%;
}

.#{$prefix}__btn-thumbnail {
    background: none;
    border: none;
    padding: 0;
    flex-shrink: 0;
    cursor: pointer;
    width: 100vw;
    height: 600px;
}

.#{$prefix}__btn-thumbnail-pic {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.#{$prefix}__overlay {
    position: fixed;
    padding-top: 20px;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    z-index: 350;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.#{$prefix}__overlay-pic {
    display: none;
    width: 100vw;
    height: fit-content;
}

.#{$prefix}__btn-close {
    margin: 0 25px 20px 0;
}

.#{$prefix}__robot-products-block {
    display: none;
    margin: 20px $side-gap-mob 0 $side-gap-mob;
    padding: 30px 0 75px 0;
    position: relative;
    &::before,
    &::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        height: 200px;
        z-index: -1;
    }
    &::before {
        background: linear-gradient(90deg, rgba(189, 140, 236, 1) 0%, rgba(138, 152, 240, 1) 50%);
    }
    &::after {
        transform: rotate(180deg);
        background: linear-gradient(
            180deg,
            #ffffff 0%,
            rgba(255, 255, 255, 0.991353) 6.67%,
            rgba(255, 255, 255, 0.96449) 13.33%,
            rgba(255, 255, 255, 0.91834) 20%,
            rgba(255, 255, 255, 0.852589) 26.67%,
            rgba(255, 255, 255, 0.768225) 33.33%,
            rgba(255, 255, 255, 0.668116) 40%,
            rgba(255, 255, 255, 0.557309) 46.67%,
            rgba(255, 255, 255, 0.442691) 53.33%,
            rgba(255, 255, 255, 0.331884) 60%,
            rgba(255, 255, 255, 0.231775) 66.67%,
            rgba(255, 255, 255, 0.147411) 73.33%,
            rgba(255, 255, 255, 0.0816599) 80%,
            rgba(255, 255, 255, 0.03551) 86.67%,
            rgba(255, 255, 255, 0.0086472) 93.33%,
            rgba(255, 255, 255, 0) 100%
        );
    }
}
.#{$prefix}__robot-products-title {
    color: #fff;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 20px;
    text-align: center;
    width: 180px;
    margin: 0 auto 30px auto;
    display: block;
}

.#{$prefix}__button-robot {
    max-width: $mobile-small;
}

@media screen and (min-width: $desktop-small) {
    .#{$prefix}__layout {
        flex-direction: row;
        overflow: hidden;
        padding-bottom: $robot-closed-height;
        flex-wrap: wrap;
        &--overlayed {
            padding-bottom: 0;
            height: 0;
        }
        &--total-look {
            padding-bottom: 0;
        }
    }
    .#{$prefix}__layout-center {
        width: 640px;
    }
    .#{$prefix}__layout-right {
        padding-top: 100px;
        align-items: flex-start;
        justify-content: flex-start;
        width: calc(100% - 640px);
    }
    .#{$prefix}__layout-right-content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: $pic-height-big;
        width: 100%;
    }

    .#{$prefix}__btn-back {
        margin-bottom: 35px;
    }
    .#{$prefix}__pictures {
        display: flex;
        grid-gap: 20px;
    }
    .#{$prefix}__thumbs-slider {
        width: auto;
    }
    .#{$prefix}__thumbnails-wrapper {
        position: relative;
        height: $pic-height-big;
        //max-height: 95vh;
        overflow: hidden;
        overflow-x: hidden;
    }
    .#{$prefix}__thumbnails {
        flex-direction: column;
        flex-shrink: 0;
        grid-gap: 20px;
        width: auto;
        transition: transform ease-in-out 0.3s;
    }
    .#{$prefix}__btn-thumbnail {
        width: 130px;
        height: 170px;
        flex-shrink: 0;
        cursor: pointer;
    }

    .#{$prefix}__picture-container {
        width: $pic-width-big;
        height: $pic-height-big;
    }
    .#{$prefix}__picture {
        width: $pic-width-big;
        height: $pic-height-big;
    }

    .#{$prefix}__brand {
        color: var(--text-black);
        font-size: 18px;
        margin-bottom: 5px;
    }
    .#{$prefix}__name {
        color: var(--text-gray);
        font-size: 16px;
    }
    .#{$prefix}__price {
        font-weight: 500;
        font-size: 20px;
        line-height: 25px;
        margin-bottom: 20px;
    }
    .#{$prefix}__button-buy {
        margin-bottom: 20px;
        width: 100%;
    }
    .#{$prefix}__sizes-title {
        color: var(--text-gray);
        margin-bottom: 10px;
    }
    .#{$prefix}__sizes-list {
        display: flex;
        flex-wrap: wrap;
        grid-gap: 10px;
        margin-bottom: 40px;
        li {
            cursor: default;
        }
    }

    .#{$prefix}__robot-block {
        position: absolute;
        left: $side-gap-desktop;
        right: $side-gap-desktop;
        width: auto;
        padding: 42px 50px 50px 50px;
        bottom: -115px;
        transition: bottom ease-in-out 0.1s;
        &--opened {
            bottom: 0;
            &::before {
                transform: rotate(180deg);
            }
        }
        &::before {
            content: url('../../../assets/svg/arrow-double-down-white.svg');
            position: absolute;
            top: 42px;
            left: 50px;
            display: block;
            transform-origin: center 10px;
        }
    }
    .#{$prefix}__robot-title {
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 25px;
        margin-bottom: 35px;
        width: auto;
    }

    .#{$prefix}__overlay {
        position: absolute;
        padding: 0;
        flex-direction: row;
        align-items: flex-start;
        .#{$prefix}__thumbnails {
            padding: 0 90px 0 100px;
            //height: 100%;
        }
        .#{$prefix}__picture {
            width: 520px;
            height: 100vh;
        }
    }

    .#{$prefix}__overlay-pic {
        display: block;
        height: 100vh;
        width: fit-content;
    }

    .#{$prefix}__btn-close {
        position: absolute;
        top: 40px;
        right: 50px;
        z-index: 360;
    }
    .#{$prefix}__robot-products-block {
        width: 100%;
        padding: 40px $side-gap-desktop 50px $side-gap-desktop;
        margin: 0;
    }
    .#{$prefix}__robot-products-title {
        width: 100%;
        margin-bottom: 120px;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 25px;
        position: relative;
        &::before {
            content: url('../../../assets/svg/arrow-double-down-white.svg');
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            transform-origin: center 10px;
        }
    }
}
