@import '../../../styles/variables.scss';

$question-size-mob: 35px;
$question-size: $round-btn-size;

.#{$prefix} {
    display: inline-flex;
    position: relative;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 15px;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.#{$prefix}__text {
    margin-right: 10px;
    color: #fff;
}

.#{$prefix}__question {
    border-radius: 50%;
    background-color: var(--red);
    color: #fff;
    width: $question-size-mob;
    height: $question-size-mob;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 300;
    font-size: 18px;
}

.#{$prefix}__tooltip {
    display: none;
    background-color: #fff;
    color: var(--text-black);
    padding: 20px;
    border: 1px solid var(--red);
    position: absolute;
    right: 0;
    bottom: $question-size-mob + 10px;
    &--opened {
        width: 210px;
        display: block;
        z-index: 300;
    }
}

.#{$prefix}__overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 300;
}

@media screen and (min-width: $desktop-small) {
    .#{$prefix}__overlay {
        display: none;
    }
    .#{$prefix}__text {
        color: var(--text-black);
    }
    .#{$prefix}__tooltip {
        bottom: $question-size + 10px;
        &--opened {
            width: 245px;
        }
    }
    .#{$prefix}__question {
        width: $question-size;
        height: $question-size;
        font-size: 13px;
    }
}
