@import '../../../styles/variables.scss';

.#{$prefix}__pending-overlay {
    display: none;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    &--opened {
        display: flex;
    }
    &--local {
        position: absolute;
        z-index: 1;
    }
}
.#{$prefix}__lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}
.#{$prefix}__lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 2px solid #fff;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #fff transparent transparent transparent;
}
.#{$prefix}__lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
}
.#{$prefix}__lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
}
.#{$prefix}__lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
}

@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
