@import '../../../../styles/variables.scss';

$look-gap: 20px;

.#{$prefix}__look-picture {
    position: relative;
    padding-top: $image-proportion-height;
    border: 1px solid var(--border-gray);
}

.#{$prefix}__item {
    width: 200px;
    height: 200px;
    position: absolute;
    background-repeat: no-repeat;
    background-size: contain;
    &--wear,
    &--top-2-no-wear {
        right: $look-gap;
        top: $look-gap;
        z-index: 2;
        width: 35%;
        height: 60%;
    }
    &--top {
        left: $look-gap;
        top: $look-gap;
        z-index: 5;
        width: 35%;
        height: 45%;
        background-position-x: right;
    }
    &--bottom {
        bottom: $look-gap;
        left: 50%;
        transform: translate(-50%);
        z-index: 4;
        width: 30%;
        height: 65%;
        background-position-x: center;
    }
    &--shoes {
        right: $look-gap;
        bottom: $look-gap;
        z-index: 6;
        width: 30%;
        height: 40%;
        background-position: bottom;
    }
    &--accessory {
        left: $look-gap;
        bottom: $look-gap;
        z-index: 1;
        width: 30%;
        height: 40%;
        background-position-y: bottom;
        background-position-x: right;
    }
}
