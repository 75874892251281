@import '../../../../styles/variables.scss';

.#{$prefix}__looks {
    @include grid(2, 15px);
    margin-bottom: 30px;
}

@media screen and (min-width: $desktop-small) {
    // .#{$prefix}__page-title-left {
    //     width: auto;
    //     padding: 0;
    // }
    .#{$prefix}__looks {
        @include grid(4, 20px);
    }
    // .#{$prefix}__controls {
    //     width: 100%;
    //     display: flex;
    //     justify-content: center;
    // }
    // .#{$prefix}__btn {
    //     width: 520px;
    // }
}
