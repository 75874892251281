@import '../../../styles/variables.scss';

$slide-gap: 10px;

.#{$prefix} {
    overflow: hidden;
}

.#{$prefix}__carousel {
    margin: 0 -1 * $slide-gap;
}

.#{$prefix}__picture-container {
    padding-top: $image-proportion-height;
    margin-bottom: 15px;
}

.#{$prefix}__card {
    padding: 0 $slide-gap;
}

.#{$prefix}__picture {
    height: 100%;
}

.#{$prefix}__link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
