@import '../../../../styles/variables.scss';

$grid-gap: 5px;
$grid-gap-desktop: 10px;

.#{$prefix}__layout-center {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.#{$prefix}__field {
    margin-bottom: 20px;
}

.#{$prefix}__field-label {
    color: var(--text-gray);
    margin-bottom: 12px;
}

.#{$prefix}__field-size-list {
    display: flex;
    grid-gap: $grid-gap;
    &--age {
        flex-wrap: wrap;
        .#{$prefix}__field-size-item {
            width: flex-item-width(3, #{$grid-gap});
        }
    }
    overflow-x: scroll;
    @include hide-scroll();
}

.#{$prefix}__field-size-item {
    color: var(--text-black);
    background-color: #fff;
    width: 100%;
    min-width: 50px;
    height: $option-btn-big-height;
    border: 1px solid var(--border-gray);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &--selected {
        border-color: var(--text-black);
        border-radius: 90px;
    }
}

.#{$prefix}__field-category-list {
    display: flex;
    flex-wrap: wrap;
    grid-gap: $grid-gap;
}

.#{$prefix}__field-category-item {
    color: var(--text-black);
    width: calc((100% - #{$grid-gap} * 2) / 3);
    min-width: 50px;
    height: 130px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    text-align: center;
    position: relative;
    padding: 60px 10px 0 10px;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center 25px;
    border: 1px solid transparent;
    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0.3;
        z-index: -1;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%),
            radial-gradient(43.81% 69.57% at 105.23% 0%, #eefecb 0%, rgba(221, 245, 254, 0) 100%),
            radial-gradient(39.07% 57.09% at 26% -11.91%, rgba(82, 245, 255, 0.7) 0%, rgba(82, 245, 255, 0) 100%),
            radial-gradient(53.23% 67.02% at 49.85% 117.23%, #b4ffd9 0%, rgba(121, 188, 239, 0.7) 100%),
            linear-gradient(209.72deg, #5baceb 17.59%, #e8f1ef 90.28%);
    }
    &--selected {
        border-color: var(--text-black);
        border-radius: 90px;
        &::before {
            border-radius: 90px;
        }
    }
    &--0 {
        background-image: url(../../../../assets/svg/clothes/tops.svg);
    }
    &--1 {
        background-image: url(../../../../assets/svg/clothes/outwear.svg);
    }
    &--2 {
        background-image: url(../../../../assets/svg/clothes/bottoms.svg);
    }
    &--3 {
        background-image: url(../../../../assets/svg/clothes/dresses.svg);
    }
    &--4 {
        background-image: url(../../../../assets/svg/clothes/shoes.svg);
    }
    &--5 {
        background-image: url(../../../../assets/svg/clothes/accessories.svg);
    }
}

.#{$prefix}__field-sex-value {
    cursor: pointer;
    border: none;
    background-color: transparent;
    width: 100%;
    border-radius: 32px;
    &--selected {
        border: 1px solid var(--text-black);
    }
}

.#{$prefix}__controls {
    width: 100%;
}

.#{$prefix}__btn-next {
    width: 100%;
}

@media screen and (min-width: $desktop-small) {
    .#{$prefix}__layout {
        display: flex;
        height: 100vh;
    }
    .#{$prefix}__layout-right {
        position: relative;
        order: 1;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        border-right: 1px solid var(--border-gray);
        width: 47%;
        height: 100%;
    }
    .#{$prefix}__layout-center {
        order: 2;
        padding-top: 45px;
        background-color: var(--back-gray);
        width: 53%;
        height: 100%;
        overflow-y: auto;
    }
    .#{$prefix}__layout-right-header {
        z-index: 5;
    }
    .#{$prefix}__options-block {
        max-width: 380px;
        margin: 0 auto;
    }
    .#{$prefix}__field-size-item {
        height: $option-btn-big-height-desktop;
    }
    .#{$prefix}__field-size-list {
        grid-gap: $grid-gap-desktop;
        &--clothes,
        &--shoes {
            flex-wrap: wrap;
            .#{$prefix}__field-size-item {
                width: flex-item-width(6, #{$grid-gap-desktop});
            }
        }
        &--age {
            .#{$prefix}__field-size-item {
                width: flex-item-width(3, #{$grid-gap-desktop});
            }
        }
    }

    .#{$prefix}__field-category-list {
        grid-gap: $grid-gap-desktop;
    }
    .#{$prefix}__field-category-item {
        width: flex-item-width(3, #{$grid-gap-desktop});
    }
}
