@import '../../../../styles/variables.scss';

.#{$prefix}__user-picture-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}

.#{$prefix}__user-picture {
    width: 145px;
}

.#{$prefix}__section-perfect {
    background-color: var(--back-gray);
    padding: 25px;
}

.#{$prefix}__section-more {
    padding: 25px $side-gap-mob 75px $side-gap-mob;
}

.#{$prefix}__section-more-header {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}

.#{$prefix}__section-perfect-title,
.#{$prefix}__section-more-title {
    margin-bottom: 20px;
    font-weight: bold;
}
.#{$prefix}__section-perfect-title {
    font-size: 13px;
    line-height: 15px;
}
.#{$prefix}__section-more-title {
    font-size: 15px;
    line-height: 20px;
}

.#{$prefix}__category {
    margin-bottom: 50px;
    &:last-child {
        margin-bottom: 0;
    }
}

.#{$prefix}__category-name {
    margin-bottom: 20px;
}

.#{$prefix}__prices-dropdown {
    width: 150px;
}

@media screen and (min-width: $desktop-small) {
    .#{$prefix} {
        display: flex;
        flex-wrap: wrap;
    }
    .#{$prefix}__btn-back {
        margin-bottom: 30px;
    }
    .#{$prefix}__user-picture-container {
        width: calc(100% - 530px);
        flex-shrink: 0;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        border-right: 1px solid var(--border-gray);
        margin: 0;
        padding: $btn-back-top-gap 50px 50px;
    }
    .#{$prefix}__user-picture {
        width: 340px;
        margin: 0 auto;
    }
    .#{$prefix}__section-perfect {
        width: 530px;
        padding: 35px 50px 30px 50px;
        .#{$prefix}__products-list {
            @include grid(2, 20px);
        }
    }
    .#{$prefix}__section-perfect-title {
        font-style: normal;
        font-weight: 500;
        font-size: 27px;
        line-height: 35px;
        margin-bottom: 30px;
    }

    .#{$prefix}__section-more {
        width: 100%;
        padding: 0;
        border-top: 1px solid var(--border-gray);
    }
    .#{$prefix}__prices {
        display: flex;
        grid-gap: 10px;
    }
    .#{$prefix}__section-more-header {
        border-bottom: 1px solid var(--border-gray);
        padding: 40px 50px;
        align-items: center;
    }
    .#{$prefix}__section-more-title {
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 25px;
        margin: 0;
    }
    .#{$prefix}__categories {
        padding: 55px 50px 50px;
    }
}
