@import '../../../../styles/variables.scss';

$image-btn-gap: 10px;
$image-btn-gap-desktop: 65px;

.#{$prefix} {
    color: #fff;
}

.#{$prefix}__overlay {
    position: absolute;
    top: 0;
    width: 100%;
    height: 430px;
    z-index: 0;
    background: linear-gradient(
        180deg,
        #ffffff 0%,
        rgba(255, 255, 255, 0.991353) 6.67%,
        rgba(255, 255, 255, 0.96449) 13.33%,
        rgba(255, 255, 255, 0.91834) 20%,
        rgba(255, 255, 255, 0.852589) 26.67%,
        rgba(255, 255, 255, 0.768225) 33.33%,
        rgba(255, 255, 255, 0.668116) 40%,
        rgba(255, 255, 255, 0.557309) 46.67%,
        rgba(255, 255, 255, 0.442691) 53.33%,
        rgba(255, 255, 255, 0.331884) 60%,
        rgba(255, 255, 255, 0.231775) 66.67%,
        rgba(255, 255, 255, 0.147411) 73.33%,
        rgba(255, 255, 255, 0.0816599) 80%,
        rgba(255, 255, 255, 0.03551) 86.67%,
        rgba(255, 255, 255, 0.0086472) 93.33%,
        rgba(255, 255, 255, 0) 100%
    );
}

.#{$prefix}__content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: $mobile-small - 2 * $side-gap-mob;
    //height: 100%;
    margin: 0 auto;
}

.#{$prefix}__facade-image {
    margin-bottom: 20px;
    width: 100%;
    // max-height: 260px;
    // display: flex;
    // align-items: center;
    // justify-content: center;
}

.#{$prefix}__input-text-link {
    background: none;
    padding: 0;
    border-radius: 0;
    margin-bottom: 5px;
    width: 325px;
    height: 65px;
    border: 1px solid rgba(255, 255, 255, 0.5);
    box-sizing: border-box;
    font-size: 13px;
    color: #fff;
    padding: 25px 40px;
    text-align: center;
    outline: none;
    background: transparent url(../../../../assets/svg/link-white.svg) no-repeat 15px;
    &:focus {
        border-color: #fff;
    }
    &--valued {
        border-color: #fff;
        border-radius: 32px;
    }
    &--error {
        border-color: var(--red);
    }
    &:disabled {
        opacity: 0.5;
    }
}

.#{$prefix}__custom-file-input,
.#{$prefix}__file-value {
    position: relative;
    width: 325px;
    height: 65px;
    flex-shrink: 0;
    background-color: #fff;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 15px;
    text-align: center;
    color: var(--text-black);
    margin-bottom: 10px;
    &::-webkit-file-upload-button {
        visibility: hidden;
    }
    &::before {
        content: 'Загрузить фото';
        display: inline-block;
        padding: 25px;
        outline: none;
        white-space: nowrap;
        -webkit-user-select: none;
        cursor: pointer;
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        top: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #fff;
        position: relative;
        z-index: 1;
    }
    &::after {
        content: '';
        background: url('../../../../assets/svg/+.svg') no-repeat center;
        position: absolute;
        top: 50%;
        left: 15px;
        transform: translateY(-50%);
        z-index: 1;
        border: 1px solid var(--border-gray);
        border-radius: 50%;
        width: 28px;
        height: 28px;
    }
}

.#{$prefix}__or-text {
    margin-bottom: 15px;
}

.#{$prefix}__custom-file-input::-webkit-file-upload-button {
    visibility: hidden;
}

.#{$prefix}__file-value {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    &::before,
    &::after {
        display: none;
    }
}

.#{$prefix}__file-value-text {
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 325px;
    overflow: hidden;
    margin-right: 10px;
}

.#{$prefix}__file-image {
    width: 50px;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    flex-shrink: 0;
    margin-right: 10px;
}

.#{$prefix}__button-find {
    width: 325px;
    height: 65px;
    background-color: #fff;
    color: var(--text-black);
    border: none;
    cursor: pointer;
    position: relative;
    flex-shrink: 0;
    &:disabled {
        color: rgba(255, 255, 255, 0.5);
        background-color: rgba(255, 255, 255, 0.2);
        cursor: not-allowed;
    }
}

.#{$prefix}__errors {
    margin-bottom: 10px;
    display: flex;
    width: 100%;
    justify-content: flex-end;
}

@media screen and (min-width: $desktop-small) {
    .#{$prefix} {
        min-height: 100vh;
        display: flex;
    }
    .#{$prefix}__content {
        max-width: unset;
        height: 100%;
    }
    .#{$prefix}__facade-image {
        display: none;
    }
    .#{$prefix}__custom-file-input {
        border: 1px solid var(--border-gray);
        border-radius: 40px;
        width: 100%;
        height: 100%;
        min-height: 200px;
        max-height: 340px;
        margin-bottom: 30px;
        flex-shrink: 1;
        &::before {
            color: var(--text-gray);
            font-style: normal;
            font-weight: normal;
            font-size: 15px;
            line-height: 20px;
            position: relative;
            top: 0;
            border-radius: 40px;
            background: #fff url('../../../../assets/svg/+-big.svg') no-repeat 50% calc(50% - 20px);
            padding-top: 50px;
        }
        &::after {
            content: none;
        }
    }
    .#{$prefix}__file-value {
        width: auto;
        height: 100%;
        min-height: 200px;
        align-items: flex-start;
        padding: 0 0 0 ($image-btn-gap + $round-btn-size);
        flex-shrink: 1;
    }
    .#{$prefix}__custom-file-input,
    .#{$prefix}__file-value {
        margin-bottom: 30px;
    }
    .#{$prefix}__or-text {
        color: var(--text-gray);
        margin-bottom: 30px;
    }
    .#{$prefix}__file-image {
        width: 242px;
        height: 100%;
        border-radius: 40px;
        margin-right: $image-btn-gap;
    }
    .#{$prefix}__file-value-text {
        display: none;
    }
    .#{$prefix}__button-find {
        width: 100%;
        background-color: var(--text-black);
        color: #fff;
        &:disabled {
            background-color: var(--border-gray);
            color: var(--text-gray);
        }
    }
    .#{$prefix}__input-text-link {
        width: 100%;
        margin-bottom: 10px;
        border-color: var(--border-gray);
        color: var(--text-black);
        background: transparent url(../../../../assets/svg/link.svg) no-repeat 15px;
        &:focus {
            border-color: var(--text-black);
        }
        &--error {
            border-color: var(--red);
        }
    }
    .#{$prefix}__page-title {
        margin-bottom: 60px;
    }
}
