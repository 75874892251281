@import './variables.scss';

.#{$prefix}__layout {
    display: flex;
    position: relative;
    min-height: calc(100vh - #{$mobile-header-height});
}

.#{$prefix}__layout-center,
.#{$prefix}__layout-right {
    width: 100%;
    padding: 25px $side-gap-mob 75px $side-gap-mob;
}

.#{$prefix}__layout-right {
    display: none;
}

.#{$prefix}__layout-facade-image {
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
        width: 100%;
        max-height: 260px;
    }
}

@media screen and (min-width: $desktop-small) {
    .#{$prefix}__layout {
        min-height: 100vh;
    }
    .#{$prefix}__layout-center,
    .#{$prefix}__layout-right {
        width: 50%;
        padding: 70px $side-gap-desktop 50px $side-gap-desktop;
        &--with-nav {
            padding-top: $btn-back-top-gap;
        }
    }
    .#{$prefix}__layout-center {
        background-color: #fff;
        //padding-top: 0;
    }
    .#{$prefix}__layout-right {
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 10;
        &--svg-container {
            padding: 50px 0 70px 0;
            height: auto;
            min-height: 100vh;
            svg {
                width: 100%;
                max-width: $svg-right-panel-max-width;
                max-height: 100%;
            }
        }
    }
    .#{$prefix}__gradients {
        top: 0;
        right: 0;
        bottom: 0;
        left: auto;
        width: 50%;
    }
    .#{$prefix}__page-title-left {
        text-align: left;
        font-style: normal;
        font-weight: 500;
        font-size: 27px;
        line-height: 35px;
        margin-bottom: 20px;
    }
    .#{$prefix}__layout-facade-image {
        display: none;
    }
    .#{$prefix}__layout-title-overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 540px;
        transform: rotate(180deg);
        background: #c6ccf7
            linear-gradient(
                180deg,
                #ffffff 0%,
                rgba(255, 255, 255, 0.991353) 6.67%,
                rgba(255, 255, 255, 0.96449) 13.33%,
                rgba(255, 255, 255, 0.91834) 20%,
                rgba(255, 255, 255, 0.852589) 26.67%,
                rgba(255, 255, 255, 0.768225) 33.33%,
                rgba(255, 255, 255, 0.668116) 40%,
                rgba(255, 255, 255, 0.557309) 46.67%,
                rgba(255, 255, 255, 0.442691) 53.33%,
                rgba(255, 255, 255, 0.331884) 60%,
                rgba(255, 255, 255, 0.231775) 66.67%,
                rgba(255, 255, 255, 0.147411) 73.33%,
                rgba(255, 255, 255, 0.0816599) 80%,
                rgba(255, 255, 255, 0.03551) 86.67%,
                rgba(255, 255, 255, 0.0086472) 93.33%,
                rgba(255, 255, 255, 0) 100%
            );
        &--total-look {
            background: #fdc1a2
                linear-gradient(
                    180deg,
                    #ffffff 0%,
                    rgba(255, 255, 255, 0.991353) 6.67%,
                    rgba(255, 255, 255, 0.96449) 13.33%,
                    rgba(255, 255, 255, 0.91834) 20%,
                    rgba(255, 255, 255, 0.852589) 26.67%,
                    rgba(255, 255, 255, 0.768225) 33.33%,
                    rgba(255, 255, 255, 0.668116) 40%,
                    rgba(255, 255, 255, 0.557309) 46.67%,
                    rgba(255, 255, 255, 0.442691) 53.33%,
                    rgba(255, 255, 255, 0.331884) 60%,
                    rgba(255, 255, 255, 0.231775) 66.67%,
                    rgba(255, 255, 255, 0.147411) 73.33%,
                    rgba(255, 255, 255, 0.0816599) 80%,
                    rgba(255, 255, 255, 0.03551) 86.67%,
                    rgba(255, 255, 255, 0.0086472) 93.33%,
                    rgba(255, 255, 255, 0) 100%
                );
        }
        &--recommended {
            background: #b9e8f9
                linear-gradient(
                    180deg,
                    #ffffff 0%,
                    rgba(255, 255, 255, 0.991353) 6.67%,
                    rgba(255, 255, 255, 0.96449) 13.33%,
                    rgba(255, 255, 255, 0.91834) 20%,
                    rgba(255, 255, 255, 0.852589) 26.67%,
                    rgba(255, 255, 255, 0.768225) 33.33%,
                    rgba(255, 255, 255, 0.668116) 40%,
                    rgba(255, 255, 255, 0.557309) 46.67%,
                    rgba(255, 255, 255, 0.442691) 53.33%,
                    rgba(255, 255, 255, 0.331884) 60%,
                    rgba(255, 255, 255, 0.231775) 66.67%,
                    rgba(255, 255, 255, 0.147411) 73.33%,
                    rgba(255, 255, 255, 0.0816599) 80%,
                    rgba(255, 255, 255, 0.03551) 86.67%,
                    rgba(255, 255, 255, 0.0086472) 93.33%,
                    rgba(255, 255, 255, 0) 100%
                );
        }
    }
}

@media screen and (min-width: $desktop-large) {
    .#{$prefix}__layout-center {
        width: 540px;
    }
    .#{$prefix}__gradients,
    .#{$prefix}__layout-right {
        width: calc(100% - 540px);
    }
}
