@import '../../../styles/variables.scss';

.#{$prefix}__prices {
    width: 100%;
    display: flex;
    grid-gap: 5px;
}

.#{$prefix}__options {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-bottom: 15px;
    flex-direction: column;
    flex-wrap: wrap;
    grid-gap: 10px;
}

.#{$prefix}__options-prices-sex {
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
    align-items: flex-start;
    margin-bottom: 10px;
}

@media screen and (min-width: $desktop-small) {
    .#{$prefix}__options {
        flex-direction: row;
        margin-bottom: 35px;
    }
    .#{$prefix}__prices {
        width: auto;
    }
    .#{$prefix}__options-prices-sex {
        flex-direction: row;
        grid-gap: 50px;
        align-items: center;
        margin: 0;
    }
}
