@import '../../../../styles/variables.scss';

.#{$prefix}__layout-center {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.#{$prefix}__picture-wrapper {
    position: relative;
}

.#{$prefix}__picture {
    width: 100%;
    margin-bottom: 20px;
}

.#{$prefix}__controls {
    width: 100%;
}

.#{$prefix}__button-next {
    margin-bottom: 5px;
    width: 100%;
}

.#{$prefix}__button-select-all {
    width: 100%;
    &:not(:disabled) {
        &:hover {
            border-radius: 32px;
        }
    }
}

.#{$prefix}__cloth-category-box {
    border: 1px solid red;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
}
.#{$prefix}__cloth-category-item {
    position: absolute;
    background-color: #fff;
    border: 1px solid var(--text-black);
    border-radius: 20px;
    padding: 10px 30px;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 15px;
    cursor: pointer;
    white-space: nowrap;
    &--selected {
        color: #fff;
        background-color: var(--text-black);
        border-color: #fff;
    }
    &--right {
        left: 100%;
    }
    &--left {
        right: 100%;
    }
}

@media screen and (min-width: $desktop-small) {
    .#{$prefix}__layout-center {
        order: 2;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 40px;
        padding-left: 100px;
        padding-right: 100px;
        z-index: 5;
        width: calc(100% - 540px);
    }
    .#{$prefix}__layout-right {
        order: 1;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        border-right: 1px solid var(--border-gray);
        width: 540px;
    }
    .#{$prefix}__button-next {
        margin-bottom: 0;
    }
    .#{$prefix}__button-select-all {
        margin-bottom: 10px;
    }
    .#{$prefix}__picture {
        margin: 0;
        width: 420px;
    }
}
