@import '../../../styles/variables.scss';

.#{$prefix}__header {
    margin-bottom: 20px;
}

.#{$prefix}__subtitle {
    text-align: left;
}

.#{$prefix}__products {
    @include grid(2, 15px);
    margin-bottom: 30px;
}

@media screen and (min-width: $desktop-small) {
    .#{$prefix}__layout-center {
        width: 100%;
    }
    .#{$prefix}__subtitle {
        max-width: unset;
    }
    .#{$prefix}__products {
        @include grid(4, 20px);
    }
    .#{$prefix}__controls {
        width: 100%;
        display: flex;
        justify-content: center;
    }
    .#{$prefix}__btn {
        width: 520px;
    }
}
