@import '../../../../styles/variables.scss';

.#{$prefix}__layout-center {
    min-height: calc(100vh - #{$mobile-header-height});
}

.#{$prefix}__products {
    @include grid(2, 15px);
    margin-bottom: 20px;
}

.#{$prefix}__user-product {
    @include grid(2, 15px);
    margin-bottom: 25px;
}

.#{$prefix}__user-product-text {
    margin: 30px 0 50px 0;
}

.#{$prefix}__btns {
    display: flex;
    grid-gap: 5px;
    position: relative;
}

.#{$prefix}__sticky-controls {
    display: flex;
    width: 100%;
    position: sticky;
    bottom: 0;
}

.#{$prefix}__controls-btn {
    width: 50%;
    border: 1px solid var(--border-gray);
    position: relative;
    min-width: 160px;
    &--prev::before,
    &--next::after {
        content: url('../../../../assets/svg/arrow-right-black.svg');
    }
    &--prev:hover::before,
    &--next:hover::after {
        content: url('../../../../assets/svg/arrow-right.svg');
    }
    &--prev {
        border-right: none;
        &::before {
            margin-right: 20px;
            transform: rotate(180deg) translateX(70px);
            position: absolute;
            left: 50%;
        }
    }
    &--next {
        &::after {
            margin-left: 20px;
            position: absolute;
            transform: translateX(70px);
            right: 50%;
        }
    }
}

.#{$prefix}__btn-buy {
    width: 100%;
    margin-bottom: 10px;
    min-width: 210px;
}

.#{$prefix}__main-block-card-image {
    width: 100%;
}

@media screen and (min-width: $desktop-small) {
    .#{$prefix}__products {
        @include grid(2, 20px);
    }
    .#{$prefix}__main-block {
        display: flex;
        grid-gap: 50px;
    }
    .#{$prefix}__main-block-card-wrapper {
        width: 250px;
        flex-shrink: 0;
    }
    .#{$prefix}__main-block-dropdown {
        width: 100%;
        margin-bottom: 20px;
    }

    .#{$prefix}__layout-center {
        order: 2;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        flex-wrap: wrap;
        padding-top: 95px;
        //width: 100%;
        width: 530px;
        min-height: 100%;
        background-color: var(--back-gray);
        position: absolute;
        right: 0;
        z-index: 80;
    }
    .#{$prefix}__layout-right {
        order: 1;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        border-right: 1px solid var(--border-gray);
        position: fixed;
        width: calc(100% - 530px - #{$left-panel-width});
        height: 100vh;
        overflow-y: auto;
    }
    .#{$prefix}__btn-back {
        margin-bottom: 35px;
    }
    .#{$prefix}__main-block-header {
        padding-top: 40px;
    }

    .#{$prefix}__btn-buy {
        width: 100%;
    }
    .#{$prefix}__controls {
        display: flex;
        grid-gap: 20px;
        width: 100%;
    }
    .#{$prefix}__controls-btn {
        &--prev {
            width: 100%;
            border: 1px solid var(--border-gray);
        }
        &--next {
            width: 100%;
        }
    }
    .#{$prefix}__controls-next-prev {
        width: 100%;
        display: flex;
        flex-direction: column;
        grid-gap: 10px;
    }
    .#{$prefix}__controls-buy-share {
        width: 100%;
    }
}

@media screen and (min-width: ($desktop-large + 1px)) {
    .#{$prefix}__layout-right {
        position: static;
        width: calc(100% - 530px);
    }

    &--cols-3 {
        .#{$prefix}__layout-right {
            position: static;
            width: calc(100% - 760px);
        }
    }

    .#{$prefix}__layout-center {
        height: 100vh;
        overflow-y: auto;
    }
}
