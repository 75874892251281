$prefix: 'garderobo';

$mobile-small: 375px;
$mobile-medium: 425px;
$desktop-small: 1024px;
$desktop-medium: 1280px;
$desktop-large: 1440px;

$max-mobile-width: 540px;

$mobile-header-height: 44px;
$side-gap-mob: 25px;
$side-gap-desktop: 50px;
$left-panel-width: 280px;
$left-panel-left-gap: 60px;
$left-panel-right-gap: 45px;
$round-btn-size: 25px;

$option-btn-small-height: 50px;
$option-btn-big-height: 65px;
$option-btn-big-height-desktop: 70px;

$btn-height: 65px;

$svg-right-panel-max-width: 500px;

$btn-back-top-gap: 30px;

$image-proportion-height: calc(4 / 3 * 100%);

@function flex-item-width($count, $gap) {
    @return calc((100% - #{$gap} * (#{$count} - 1)) / #{$count});
}

@function grid-cell-width($count, $gap) {
    @return repeat(#{$count}, calc(100% / #{$count} - ((#{$count} - 1) * #{$gap}) / #{$count}));
}

@mixin grid($count, $gap) {
    display: grid;
    grid-template-columns: grid-cell-width(#{$count}, #{$gap});
    grid-gap: $gap;
    width: 100%;
}

@mixin hide-scroll() {
    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
        display: none;
    }
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}
