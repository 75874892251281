@import '../../../../styles/variables.scss';

.#{$prefix}__header {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 30px;
}

.#{$prefix}__btn-train {
    width: auto;
}

.#{$prefix}__btn {
    margin: 0 0 10px 0;
}

.#{$prefix}__categories {
    width: 100%;
    display: flex;
    grid-gap: 5px;
    margin-bottom: 30px;
    overflow-x: scroll;
    @include hide-scroll();
}

.#{$prefix}__price {
    padding: 18px 20px;
    border: 1px solid var(--border-gray);
    text-align: center;
    &--selected {
        border-color: var(--text-black);
        border-radius: 90px;
    }
}

.#{$prefix}__prices-dropdown {
    margin-bottom: 15px;
    width: 150px;
}

.#{$prefix}__looks {
    @include grid(2, 15px);
    margin-bottom: 30px;
}

@media screen and (min-width: $desktop-small) {
    .#{$prefix}__header {
        flex-direction: row;
        justify-content: space-between;
    }
    .#{$prefix}__layout-center {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        flex-wrap: wrap;
        width: 100%;
    }
    .#{$prefix}__layout-right {
        display: none;
    }

    .#{$prefix}__options {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 35px;
    }
    .#{$prefix}__page-title-left {
        width: auto;
        padding: 0;
    }
    .#{$prefix}__categories {
        width: auto;
        margin: 0;
        overflow-x: unset;
    }
    .#{$prefix}__prices-dropdown {
        margin: 0;
        width: 200px;
    }
    .#{$prefix}__looks {
        @include grid(4, 20px);
    }
    .#{$prefix}__controls {
        width: 100%;
        display: flex;
        justify-content: center;
    }
    .#{$prefix}__btn {
        width: 520px;
        margin: 0 20px 0 0;
    }
}

@media screen and (min-width: $desktop-large) {
    .#{$prefix}__layout-center {
        width: 100%;
    }
}
